import { reducer as dashboard } from './state/dashboard';
import { reducer as user } from './state/user';

import { combineReducers } from 'redux';

const reducers = combineReducers({
    dashboard,
    user
})

export default reducers;