import React, { useState, useMemo, useEffect } from 'react';

import { Layout, Menu, Breadcrumb, Spin, Button } from 'antd';

import { UserState } from '../state/user';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams,
    useRouteMatch,
    useHistory
} from "react-router-dom";

import { connect } from 'react-redux';

import routes from '../routes/dashboard';

import { updateDashboard } from '../state/dashboard';

import { RouteConfig } from '../type/base';

import './dashboard.less';

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

const menuRoutes = routes.filter(r => r.menuItem);

const Dashboard = (props: any) => {
    let { path, url } = useRouteMatch();

    const history = useHistory();

    const user = props.user as UserState;

    const [collapsed, setCollapsed] = useState(false);

    useEffect(() => {
        history.push('/dashboard/users');
    }, []);

    const onCollapse = (collapsed: boolean) => {
        setCollapsed(collapsed);
    };

    const onLinkClick = (r: RouteConfig) => {
        props.dispatch(updateDashboard({
            currentRoute: r
        }));
    }

    const userMenuRoutes = useMemo(() => {
        if(!user.token) return menuRoutes;

        return menuRoutes.filter(r => {
            if(!r.rolePermissions) return true;

            let premissions = r.rolePermissions;

            for(let i = 0; i < user.roles.length; i++) {
                if(premissions.includes(user.roles[i])) {
                    return true;
                }
            }

            return false;
        })
    }, [user])

    const userRoutes = useMemo(() => {
        if(!user.token) return routes;

        return routes.filter(r => {
            if(!r.rolePermissions) return true;

            let premissions = r.rolePermissions;

            for(let i = 0; i < user.roles.length; i++) {
                if(premissions.includes(user.roles[i])) {
                    return true;
                }
            }

            return false;
        })
    }, [user]);

    const handleLogout = () => {
        localStorage.removeItem('user');

        window.location.href = "/";
    }

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
                <Menu theme="dark" defaultSelectedKeys={['0']} mode="inline">
                    {
                        userMenuRoutes.map((r, idx) => {
                            return (
                                <Menu.Item key={idx + ''} icon={<r.icon/>} onClick={() => onLinkClick(r)}>
                                    {r.name}
                                    <Link to={`${url}${r.path}`}></Link>
                                </Menu.Item>

                            )
                        })
                    }
                </Menu>
            </Sider>
            <Layout className="site-layout">
                {
                    !user.token?
                    <Spin></Spin>
                    :
                    <Layout>
                        <Header className="site-layout-background" style={{ padding: 0 }} >
                            <Button type='link' size='large' 
                            style={{position: 'absolute', right: 8, fontSize: 18, fontWeight: 'bold', top: 8}}
                            onClick={handleLogout}>
                                登出
                            </Button>
                        </Header>

                        <Content style={{ margin: '0 16px' }}>
                            <Breadcrumb style={{ margin: '16px 0' }}>
                                <Breadcrumb.Item>{props.dashboard.currentRoute.name}</Breadcrumb.Item>
                            </Breadcrumb>
                            {
                                userRoutes.map((r, idx) => {
                                    return (
                                        <Route key={idx} path={`${path}${r.path}`}>
                                            <r.component />
                                        </Route>
                                    )
                                })
                            }
                        </Content>

                        <Footer style={{ textAlign: 'center', zIndex: 16 }}>AIRIDER ©2020 Created by AIRIDER</Footer>
                    </Layout>
                }
                

                
                
            </Layout>
        </Layout>
    )
}

const mapStateToProps = (state: any) => {
    return {
      dashboard: state.dashboard,
      user: state.user
    }
  }

export default connect(mapStateToProps)(Dashboard);