import React, {useState, useEffect, useMemo, useRef} from 'react';

import { useParams, useHistory } from 'react-router-dom';

import { connect } from 'react-redux';

import { UserState } from '../state/user';

import { usePaginationFetcher } from '../component/hook';

import {
    Form,
    Input,
    Button,
    Select,
    Cascader,
    DatePicker,
    InputNumber,
    TreeSelect,
    Switch,
    Col,
    Spin,
    message,
    Divider,
    Typography,
    Card,
    Collapse,
    Table,
    Layout,
    Upload,
    Row
  } from 'antd';

import { Group, RentalUser, Site } from '../type/park-cloud';

import  { Pagination as PaginationType  } from '../type/base';

import {
  DeleteOutlined,
  EditOutlined,
  UploadOutlined ,
  PlusOutlined
} from '@ant-design/icons';

import { parkCloud } from '../util/util';
import { RcFile } from 'antd/lib/upload';
import { RcCustomRequestOptions } from 'antd/lib/upload/interface';
const {Title} = Typography;
const { Panel } = Collapse;
const { Option } = Select;
const { TextArea } = Input;
const { Content } = Layout;

const columns = [
  {
      title: '用戶名稱',
      dataIndex: 'name',
      key: 'name',
  },
  {
    title: '開始時間',
    dataIndex: 'start_time',
    key: 'start_time',
    render: (value: any, record: RentalUser) => (
        <span>
          {new Date(value).toLocaleString()}
        </span>
      ),
  },
  {
    title: '結束時間',
    dataIndex: 'end_time',
    key: 'end_time',
    render: (value: any, record: RentalUser) => (
        <span>
          {new Date(value).toLocaleString()}
        </span>
      ),
  },
  {
      title: '動作',
      key: 'action',
      render: (value: any, record: RentalUser) => (
        <span>
          <Button icon={<DeleteOutlined />} style={{marginLeft: 8}}></Button>
        </span>
      ),
    },
];

const UsersTable = (props: any) => {

  const fetcher = useMemo(() => {
      return async (pagination: PaginationType) => {
          let body = await parkCloud.fetchGroupRentalUsers(props.group.id, {
              page: pagination.current_page - 1,
              page_size: pagination.page_size
          });

          // backend start from zero
          body.pagination.current_page += 1;
          return body;
      }
  }, [props.group]);

  const {loaded, error, results, pagination, setPagination} = usePaginationFetcher(fetcher, {
      current_page: 1,
      page_size: 12,
      total_elements: 100,
      total_pages: 5,
      sort: {
          by: 'id',
          direction: 'asc'
      }
  }, [] as RentalUser[]);

  const _data = useMemo(() => {
      return results.map(r => {
          return {
              ...r,
              key: r.id
          };
      })
  }, [results]);

  return (
      <Table dataSource={_data} columns={columns} loading={!loaded} pagination={{
          defaultCurrent: pagination.current_page,
          total: pagination.total_elements,
          current: pagination.current_page,
          pageSize: pagination.page_size,
          onChange: (value) => {
              let next = {
                  ...pagination
              };

              next.current_page = value;

              setPagination(next);
          }
      }} />
  )
}

const Sites = (props: any) => {
  const { group } = props;
  const [editSiteId, setEditSiteId] = useState(0);
  const [sites, setSites] = useState<Site[]>([]);

  const fetcher = useMemo(() => {
      return async (pagination: PaginationType) => {
          let body = await parkCloud.fetchGroupSites(group? group.id : 1, {
              page: pagination.current_page - 1,
              page_size: pagination.page_size,
              sort_direction: pagination.sort.direction
          });

          // backend start from zero
          body.pagination.current_page += 1;
          return body;
      }
  }, [group]);

  const {loaded, error, results, pagination, setPagination} = usePaginationFetcher(fetcher, {
      current_page: 1,
      page_size: 10,
      total_elements: 100,
      total_pages: 5,
      sort: {
          by: 'id',
          direction: 'desc'
      }
  }, [] as Site[]);

  useEffect(() => {
    setSites(results.map(r => {
        return {
            ...r,
            key: r.id
        };
    }));
  }, [results]);

  const submitEditSite = async (s: Site) => {
    try {
      s.params = {
        rental_car_number: s.total_rental_car_number,
        rental_moto_number: s.total_rental_moto_number
      }
      await parkCloud.updateGroupSite(group.id, s);
      message.success('成功');
    }catch(err) {
      message.error('失敗');
    }

    setPagination({...pagination})
  }

  const handleEdit = (s: Site) => {
    if(editSiteId) {
      setEditSiteId(0);

      submitEditSite(s);
    }else {
      setEditSiteId(s.id);
    }
  }

  return (
    <Row>
      {
        sites.map(s => {
          return (
            <Col key={s.id} xs={{ span: 8 }} lg={{ span: 8}} style={{padding: 8}}>
                  <Card title={s.name} bordered={true} hoverable 
                    extra={<Button type="link" icon={<EditOutlined/>} onClick={() => handleEdit(s)}>
                      {
                        editSiteId?
                        <span>確定</span>
                        :
                        <span>編輯</span>
                      }
                    </Button>}
                    style={{ width: '100%' }}>
                        <div>
                            <span>剩餘汽車數:</span>
                            <span>{s.current_car_number}</span>
                        </div>
  
                        <div>
                            <span>剩餘機車數:</span>
                            <span>{s.current_moto_number}</span>
                        </div>

                        {
                          editSiteId === s.id?
                          <div>
                            <div>
                                <span>租賃汽車數:</span>
                                <Input defaultValue={s.total_rental_car_number} value={s.total_rental_car_number}
                                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                  let next = [...sites];

                                  next.forEach(_s => {
                                    if(_s.id === s.id) {
                                      s.total_rental_car_number = Number(evt.target.value);
                                    }
                                  });

                                  setSites(next);
                                }}></Input>
                            </div>

                            <div>
                                <span>租賃機車數:</span>
                                <Input defaultValue={s.total_rental_moto_number} value={s.total_rental_moto_number}
                                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                  let next = [...sites];

                                  next.forEach(_s => {
                                    if(_s.id === s.id) {
                                      s.total_rental_car_number = Number(evt.target.value);
                                    }
                                  });

                                  setSites(next);
                                }}></Input>
                            </div>

                            <div>
                                <span>剩餘租賃汽車數:</span>
                                <span>{s.current_rental_car_number}</span>
                            </div>

                            <div>
                                <span>剩餘租賃機車數:</span>
                                <span>{s.current_rental_moto_number}</span>
                            </div>
                          </div>
                          :
                          <div>
                            <div>
                                <span>租賃汽車數:</span>
                                <span>{s.total_rental_car_number}</span>
                            </div>

                            <div>
                                <span>租賃機車數:</span>
                                <span>{s.total_rental_moto_number}</span>
                            </div>

                            <div>
                                <span>剩餘租賃汽車數:</span>
                                <span>{s.current_rental_car_number}</span>
                            </div>

                            <div>
                                <span>剩餘租賃機車數:</span>
                                <span>{s.current_rental_moto_number}</span>
                            </div>
                          </div>
                        }
                    </Card>
              </Col>
          )
        })
      }
    </Row>
  )
}

const SiteForm = (props: any) => {

    const [group, setGroup] = useState<Group | undefined>(undefined);
    const [loaded, setLoaded] = useState<boolean>(false);

    let { id } = useParams();
    let history = useHistory();

    id = Number(id);

    const init = async () => {
        if(id === 0) {
            setLoaded(true);
            return;
        }

        let _site = await parkCloud.fetchGroup(id);

        setGroup(_site);
        setLoaded(true);
    }

    useEffect(() => {
        init();
    }, []);

    const asyncSumbit = async (values: Group) => {
      values.total_car_number = Number(values.total_car_number);
      values.total_moto_number = Number(values.total_moto_number);

      values.lat = Number(values.lat);
      values.lon = Number(values.lon);

      let hide = message.loading('資料上傳中...');

      try {
        if(id === 0) {
        }else {
          values.id = id;
        }
      }catch(err) {
        message.error('失敗!');
        hide();
        return;
      }

      hide();
      message.success('成功!');

      history.goBack();
    }

    const submit = (values: any) => {
      asyncSumbit(values);
    }

    const handleUploadRentalUsers = (options: RcCustomRequestOptions) => {
        const { file, onProgress, onSuccess, onError } = options;

        async function upload() {
            try {
                let resp = await parkCloud.importGroupRentalUsers(group!.id, file);
                onSuccess(resp, file);
                message.info('上傳成功');
            }catch(err) {
                onError(err);
                message.error('上傳失敗!');
            }

            setGroup({...group} as Group);
        }

        upload();
    }

    if(!loaded) {
        return (
            <div>
              <Spin></Spin>
            </div>
        )
    }

    return (
    <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
    <Form
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        initialValues={{ size: 'default' }}
        size={'default' as any}
        onFinish={submit}
      >

        

        <Collapse defaultActiveKey={['1']}>
        <Panel header={<Title level={4}>群組資訊</Title>} key="1">
            <Form.Item name={'name'} label="名稱" initialValue={group? group.name : ''}>
              <Input />
            </Form.Item>

            <div style={{display: 'flex', marginTop: 20}}>
              <div style={{flex: 1}}></div>
              <div style={{flex: 4}}><Button htmlType="submit" type="primary" block>提交</Button></div>
              <div style={{flex: 1}}></div>
          </div>
        </Panel>
        <Panel header={<Title level={4}>月租用戶</Title>} key="2">

            <Content  style={{flex: 'none', padding: 20, border: '1px solid', borderColor: '#aaa', borderRadius: 6, minHeight: 72}}>
                <Content style={{float: 'right', height: '100%'}}>
                <Upload
                  showUploadList={false}
                  customRequest={handleUploadRentalUsers}>
                    <Button type='primary' icon={<UploadOutlined />}>匯入</Button>
                </Upload>
                </Content>
            </Content>

            <Content style={{marginTop: 20}}>
                <UsersTable group={group} />
            </Content>
        </Panel>

        <Panel header={<Title level={4}>場站</Title>} key="3">
          
          {/* <Content  style={{flex: 'none', padding: 20, border: '1px solid', borderColor: '#aaa', borderRadius: 6, minHeight: 72}}>
              <Content style={{float: 'right', height: '100%'}}>
                <Button type='primary' icon={<PlusOutlined />}>新增</Button>
              </Content>
          </Content> */}

          <Content style={{marginTop: 20}}>
              <Sites group={group} />
          </Content>
        </Panel>
      </Collapse>

      
      
        
      </Form>
    </div>)
}

const mapStateToProps = (state: any) => {
  return {
    user: state.user
  }
}

export default connect(mapStateToProps)(SiteForm);