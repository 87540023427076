import React, { useEffect, useState } from 'react';

import { Site } from '../type/park-cloud';

import { Card, Row, Col, Button, Popover, Spin, Modal, message, Pagination, Layout } from 'antd';

import { useRouteMatch, Link, useHistory } from 'react-router-dom';

import { parkCloud } from '../util/util';

import { Pagination as PaginationType } from '../type/base';

import {
    PlusOutlined,
    EditOutlined,
    EllipsisOutlined,
    DeleteOutlined
} from '@ant-design/icons';

const { confirm } = Modal;
const { Footer, Content } = Layout;

const Extra = (props: any) => {
    const [visiable, setVisiable] = useState(false);

    let history = useHistory();

    let site = props.site as Site;

    const onEdit = () => {
        history.push(`/dashboard/site-form/${site.id}`);
    }

    const asyncDeleteSite = async () => {
        try {
            await parkCloud.deleteSite(site.id);
        }catch(err) {
            message.error('刪除失敗!');
            return;
        }

        message.success('刪除成功!');
        props.onDelete && props.onDelete();
    }

    const deleteSite = (evt: React.MouseEvent<HTMLElement>) => {

        confirm({
            title: '刪除場站',
            content: '確定刪除嗎?',
            okText: '確認',
            cancelText: '取消',
            onOk() {
                asyncDeleteSite();
            }
        })
    }
    
    return (
        <div onClick={(evt: React.MouseEvent<HTMLElement>) => {
            setVisiable(true);

            evt.preventDefault();
        }}>
            <EllipsisOutlined />

            <Popover
                placement='bottom'
                content={
                <div>
                    <Button type="text" icon={<EditOutlined/>} block onClick={onEdit}>修改</Button>
                    <Button type="text" icon={<DeleteOutlined/>} block onClick={deleteSite}>刪除</Button>
                </div>}
                trigger="click"
                visible={visiable}
                onVisibleChange={v => setVisiable(v)}
            ></Popover>
        </div>
    )
}

const Sites = (props: any) => {

    const [sites, setSites] = useState<Site[]>([]);
    const [loaded, setLoaded] = useState(false);
    const [pagination, setPagination] = useState<PaginationType>({
        current_page: 0,
        page_size: 20,
        total_elements: 100,
        total_pages: 5,
        sort: {
            by: 'id',
            direction: 'asc'
        }
    });

    const fetchSites = async (pagination: PaginationType) => {
        let body = await parkCloud.fetchSites({
            page_size: 20,
            page: pagination.current_page
        });

        setSites(body.content);
        setPagination(body.pagination);
    }

    const init = async () => {
        await fetchSites(pagination);

        setLoaded(true);
    }

    useEffect(() => {
        init();
    }, []);

    if(!loaded) {
        return (
            <div>
                <Spin></Spin>
            </div>
        )
    }

    return (
    <Layout style={{ padding: 0 }}>
        <Content style={{minHeight: 240}}>
            <Row>
                {
                    sites.map(s => {
                        return (
                            <Col key={s.id} xs={{ span: 12 }} lg={{ span: 6}} style={{padding: 8}}>
                                

                                <Link to={`/dashboard/site-form/${s.id}`}>
                                    <Card title={s.name} bordered={true} hoverable 
                                    extra={<Extra 
                                        onDelete={() => {
                                            setSites((sites) => {
                                                return sites.filter(_s => _s.id !== s.id);
                                            })
                                        }}
                                        site={s} 
                                        />} 
                                    style={{ width: '100%' }}>
                                        <div>
                                            <span>剩餘汽車數:</span>
                                            <span>{s.current_car_number}</span>
                                        </div>

                                        <div>
                                            <span>剩餘機車數:</span>
                                            <span>{s.current_moto_number}</span>
                                        </div>
                                    </Card>
                                </Link>
                            </Col>
                            
                        )
                    })
                }
            </Row>
        </Content>

        

        <Footer style={{zIndex: 2}}>

            <div style={{margin: 'auto', display: 'block'}}>
                <Pagination 
                defaultCurrent={pagination.current_page + 1} 
                total={pagination.total_elements} 
                pageSize={pagination.page_size}
                onChange={(value) => {
                    let next = {
                        ...pagination
                    };

                    next.current_page = value - 1;

                    fetchSites(next);
                }}
                style={{margin: 'auto', display: 'block'}} />
            </div>

        </Footer>

        

        <Link to={`/dashboard/site-form/0`} style={{zIndex: 70}}>
            <Button type="primary" shape="circle" icon={<PlusOutlined/>} className='float-action-btn' />
        </Link>

        
    </Layout>)
}

export default Sites;