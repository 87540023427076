import React, { useEffect, useMemo, useState } from 'react';

import { Layout, Table, Select } from 'antd';

import { parkCloud } from '../util/util';

import { Site, CarHistory } from '../type/park-cloud';

import { Pagination as PaginationType } from '../type/base';

import { usePaginationFetcher } from '../component/hook';

import { translator } from '../util/util';
import { Switch } from 'react-router-dom';

const { Content } = Layout;
const { Option } = Select;


const columns = [
    {
        title: '車牌',
        dataIndex: 'plate',
        key: 'plate',
    },
    {
        title: '車種',
        dataIndex: 'car_type',
        key: 'car_type',
        render: (value: string) => {
            return translator.translate(value);
        }
    },
    {
        title: '事件',
        dataIndex: 'event',
        key: 'event',
        render: (value: string) => {
            return translator.translate(value);
        }
    },
    {
        title: '時間',
        dataIndex: 'timestamp',
        key: 'timestamp',
        render: (timestamp: string) => {
            return new Date(timestamp).toLocaleString();
        }
    },
];

const History = (props: any) => {

    const [site, setSite] = useState<Site | undefined>();
    const [sites, setSites] = useState<Site[]>([]);

    useEffect(() => {
        async function fetchSites() {
            let _sites = await parkCloud.fetchSites();

            setSites(_sites.content);
            if(_sites.content.length > 0) {
                setSite(_sites.content[0]);
            }
        }

        fetchSites();
    }, []);

    const fetcher = useMemo(() => {
        return async (pagination: PaginationType) => {
            let body = await parkCloud.fetchCarHistory(site? site.id : 1, {
                page: pagination.current_page - 1,
                page_size: pagination.page_size,
                sort_direction: pagination.sort.direction
            });

            // backend start from zero
            body.pagination.current_page += 1;
            return body;
        }
    }, [site]);

    const {loaded, error, results, pagination, setPagination} = usePaginationFetcher(fetcher, {
        current_page: 1,
        page_size: 10,
        total_elements: 100,
        total_pages: 5,
        sort: {
            by: 'id',
            direction: 'desc'
        }
    }, [] as CarHistory[]);

    const _data = useMemo(() => {
        return results.map(r => {
            return {
                ...r,
                key: r.id
            };
        })
    }, [results]);

    const handleSiteChange = (value: string) => {
        async function change() {
            let id = Number(value);

            if(isNaN(id)) {
                return;
            }

            let site = await parkCloud.fetchSite(id);

            if(site) {
                setSite(site);
                let nextPagination = {...pagination};
                nextPagination.current_page = 1;
                setPagination(nextPagination);
            }
        }

        change();
    }

    const handleSiteSearch = (value: string) => {

    }

    return (
        <Layout style={{ padding: 24, minHeight: 360 }}>
            <Content className="site-layout-background" style={{flex: 'none', padding: 20}}>
                <Content style={{float: 'right', height: '100%'}}>
                    <Select
                        showSearch
                        style={{ width: 200 }}
                        placeholder="場站"
                        optionFilterProp="children"
                        onChange={handleSiteChange}
                        onSearch={handleSiteSearch}
                        defaultValue={`${site? site.id : '1'}`}
                        filterOption={(input, option) =>
                            option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {
                            sites.map(s => <Option value={`${s.id}`}>{s.name}</Option>)
                        }
                    </Select>
                </Content>
            </Content>

            <Content className="site-layout-background" style={{marginTop: 20, padding: 12}}>
                <Table dataSource={_data} columns={columns} loading={!loaded} pagination={{
                    defaultCurrent: pagination.current_page,
                    total: pagination.total_elements,
                    current: pagination.current_page,
                    pageSize: pagination.page_size,
                    onChange: (value) => {
                        let next = {
                            ...pagination
                        };

                        next.current_page = value;

                        console.log(next)

                        setPagination(next);
                    }
                }} />
            </Content>
        </Layout>
    )
}

export default History;