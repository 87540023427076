import React from 'react';
import {
  BrowserRouter as Router,
} from 'react-router-dom';

import { createStore } from 'redux';
import { Provider } from 'react-redux';

import reducers from './reducers';

import Root from './Root';

import './App.less';


let store = createStore(reducers);

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Root></Root>
      </Router>
    </Provider>
    
  );
}

export default App;
