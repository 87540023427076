import { RouteConfig, KeyMap } from '../type/base';

import routes from '../routes/dashboard';

const UPDATE_DASHBOARD = 'UPDATE_DASHBOARD';

export interface DashboardState extends KeyMap {
    currentRoute: RouteConfig
}

interface UpdateDashboardAction {
    type: typeof UPDATE_DASHBOARD,
    state: DashboardState
}

export type DashboardActions = UpdateDashboardAction;

export function reducer(state: DashboardState = {
    currentRoute: routes[0]
}, action: DashboardActions) {
    switch(action.type) {
        case UPDATE_DASHBOARD:
            let newState = {...state, ...action.state};
            return newState;
        default: return state;
    }
}

export function updateDashboard(state: DashboardState): DashboardActions {
    return {
        type: UPDATE_DASHBOARD,
        state: state
    }
}