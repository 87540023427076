import { KeyMap } from '../type/base';

const UPDATE_USER = 'UPDATE_USER';

export interface UserState extends KeyMap {
    name: string,
    token: string,
    roles: string[]
}

interface UpdateUserAction {
    type: typeof UPDATE_USER,
    state: UserState
}

export type UserActions = UpdateUserAction;

export function reducer(state: UserState | {} = {}, action: UserActions) {
    switch(action.type) {
        case UPDATE_USER:
            let newState = {...state, ...action.state};

            localStorage.setItem('user', JSON.stringify(newState));
            
            return newState;
        default: return state;
    }
}

export function updateUser(state: UserState): UserActions {
    return {
        type: UPDATE_USER,
        state: state
    }
}