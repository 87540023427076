import React, { useEffect, useState } from 'react';

import { UserState } from './state/user';

import {
    Switch,
    Route,
    useHistory
} from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
  
import routes from './routes/app';

import { Spin } from 'antd';

import { parkCloud } from './util/util';

import { updateUser } from './state/user';

function Root() {
    const [loaded, setLoaded] = useState(false);

    let history = useHistory();
    const dispatch = useDispatch();

    const user = useSelector((state: any) => state.user) as UserState;

    const init = async () => {
        let user: any = localStorage.getItem('user');
        if(user) {
            try {
                user = JSON.parse(user);
            }catch(err) {
                console.warn(`cannot parse user: ${user}`);
                user = undefined;
            }
        }

        if(!user) {
            user = {};
        }

        if(!user.token) {
            history.push('/login')
        }else {
            dispatch(updateUser(user));
        }

        setLoaded(true);
    }

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        if(user.token) {
            parkCloud.setToken(user.token);
            history.push('/dashboard')
        }
    }, [user.token]);

    return (
        <div>
        {
            !loaded?
            <Spin></Spin>
            :
            <div></div>
        }
          <Switch>
            {
              routes.map((r, idx) => {
                return (
                  <Route key={idx} path={r.path}>
                    <r.component  />
                  </Route>
                )
              })
            }
          </Switch>
        </div>
    )
}

export default Root;