import { KeyMap } from '../type/base';

const lang = {
    'enter_site': '進場',
    'exit_site': '出場',
    'enter_slot': '進入車位',
    'exit_slot': '離開車位',
    'C': '汽車',
    'M': '機車'
} as KeyMap

export default lang;