import { T } from 'antd/lib/upload/utils';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Pagination as PaginationType, PaginationBody } from '../type/base';

export function usePaginationFetcher<T>(fetcher: (pagination: PaginationType) => Promise<PaginationBody<T>>, initialPagination: PaginationType, initialValue: T) {
    
    const [results, setResults] = useState<T>(initialValue);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState<any>();
    const [pagination, setPagination] = useState<PaginationType>(initialPagination);

    const fetch = useCallback(() => {
        setLoaded(false);
    }, []);

    useEffect(() => {
        if(loaded) return;

        async function fetchData() {
            try {
                let body = await fetcher(pagination) as PaginationBody<T>;

                setResults(body.content);
                setPagination(body.pagination);

                
            }catch(err) {
                setError(err);
                return;
            }

            setLoaded(true);
        }

        fetchData();
    }, [loaded]);

    useEffect(() => {
        setLoaded(false);
    }, [fetcher])

    const pager = (paginaion: PaginationType) => {
        setPagination(paginaion);
        // use loaded to trigger fetcher
        setLoaded(false);
    }

    return {
        loaded,
        results,
        pagination,
        error,
        setPagination: pager,
        fetch
    }
}