import React, { useEffect, useMemo, useState } from 'react';

import { Layout, Table, Select, DatePicker, message, Button } from 'antd';

import { parkCloud } from '../util/util';

import { DownloadOutlined } from '@ant-design/icons';

import { Site, CarHistory, Group } from '../type/park-cloud';

import { Pagination as PaginationType, PaginationBody } from '../type/base';

import { usePaginationFetcher } from '../component/hook';

import { translator } from '../util/util';
import { Switch } from 'react-router-dom';
import moment from 'moment';

const { Content } = Layout;
const { Option } = Select;


const columns = [
    {
        title: '序號',
        dataIndex: 'sequenceNumber',
        key: 'sequenceNumber',
        //defaultSortOrder: 'asc',
        sorter: (a: any, b: any) => { return a.sequenceNumber - b.sequenceNumber; }
    },
    {
        title: '車牌',
        dataIndex: 'plate',
        key: 'plate',
    },
    {
        title: '車種',
        dataIndex: 'car_type',
        key: 'car_type',
        render: (value: string) => {
            return translator.translate(value);
        }
    },
    {
        title: '進場時間',
        dataIndex: 'enter_time',
        key: 'enter_time',
        render: (timestamp: string) => {
            return new Date(timestamp).toLocaleString();
        }
    },
    {
        title: '出場時間',
        dataIndex: 'exit_time',
        key: 'exit_time',
        render: (timestamp: string) => {
            if(!timestamp) {
                return '尚未出場'
            }

            return new Date(timestamp).toLocaleString();
        }
    },
];

const History = (props: any) => {

    const [group, setGroup] = useState<Group | undefined>();
    const [groups, setGroups] = useState<Group[]>([]);
    const [site, setSite] = useState<Site | undefined>();
    const [sites, setSites] = useState<Site[]>([]);

    const [startTime, setStartTime] = useState(moment().subtract(1, 'd').toDate());
    const [endTime, setEndTime] = useState(new Date());

    useEffect(() => {
        async function fetchSites() {
            let _sites = await parkCloud.fetchSites();

            setSites(_sites.content);
            if(_sites.content.length > 0) {
                setSite(_sites.content[0]);
            }
        }

        async function fetchGroups() {
            let _groups = await parkCloud.fetchGroups();

            setGroups(_groups.content);

            if(_groups.content.length > 0) {
                setGroup(_groups.content[0]);
            }
        }

        fetchSites();
        fetchGroups();
    }, []);

    const fetcher = useMemo(() => {

        let totalContent: any[] | undefined = undefined;

        return async (pagination: PaginationType) => {
            
            if(!totalContent) {
                
                totalContent = await parkCloud.fetchGroupSiteUsageReport(group? group.id : 1, site? site.id : 1, {
                    start_time: startTime.toISOString(),
                    end_time: endTime.toISOString()
                });

                pagination.current_page = 1;
                pagination.total_elements = totalContent.length;
                pagination.total_pages = Math.floor(totalContent.length / 10) + 1;
            }else {
                //content = totalContent.slice(10 * (pagination.current_page - 1), 10 * (pagination.current_page - 1) + 10);
            }
            
            return {
                content: totalContent,
                pagination
            };
        }
    }, [site, group, startTime, endTime]);

    const {loaded, error, results, pagination, setPagination} = usePaginationFetcher(fetcher, {
        current_page: 1,
        page_size: 10,
        total_elements: 100,
        total_pages: 5,
        sort: {
            by: 'id',
            direction: 'asc'
        }
    }, [] as any[]);

    const _data = useMemo(() => {
        return results.map(r => {
            return {
                ...r,
                key: r.id
            };
        })
    }, [results]);

    const handleSiteChange = (value: string) => {
        async function change() {
            let id = Number(value);

            if(isNaN(id)) {
                return;
            }

            let site = await parkCloud.fetchSite(id);

            if(site) {
                setSite(site);
                let nextPagination = {...pagination};
                nextPagination.current_page = 1;
                setPagination(nextPagination);
            }
        }

        change();
    }

    const handleGroupChange = (value: string) => {
        async function change() {
            let id = Number(value);

            if(isNaN(id)) {
                return;
            }

            let group = await parkCloud.fetchGroup(id);

            if(group) {
                setGroup(group);
                let nextPagination = {...pagination};
                nextPagination.current_page = 1;
                setPagination(nextPagination);
            }
        }

        change();
    }

    const handleStartTimeChange = (value: any) => {
        setStartTime(value.toDate());
    }

    const handleEndTimeChange = (value: any) => {
        setEndTime(value.toDate());
    }

    const handleSiteSearch = (value: string) => {

    }

    const download = (filename: string, text: string) => {
        //text = text.replace(/\n/g, '\r\n');
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);
      
        element.style.display = 'none';
        document.body.appendChild(element);
      
        element.click();
      
        document.body.removeChild(element);
    }

    const handleExport = async () => {
        let hide = message.loading('處理中...')
        try {
            let data = await parkCloud.fetchGroupSiteUsageReport(group? group.id : 1, site? site.id : 1, {
                start_time: startTime.toISOString(),
                end_time: endTime.toISOString()
            });

            let text = '序號,車號,車種,入場時間,出場時間,停車時數\n';

            data.forEach(d => {
                let { sequenceNumber, plate, enter_time, exit_time, car_type} = d;
                let carType = '汽車';

                if(car_type === 'M') {
                    carType = '機車';
                }

                let enterTime, exitTime;

                enterTime = moment(enter_time).format('YYYY-MM-DD HH:mm:ss');

                let parkTime: any = '無資料';

                if(exit_time) {
                    let diff = (new Date(exit_time).getTime() - new Date(enter_time).getTime()) / (3600 * 1000);
                    
                    parkTime = Math.floor(diff * 10) / 10;


                    exitTime = moment(exit_time).format('YYYY-MM-DD HH:mm:ss');
                }else {
                    exitTime = '尚未出場'
                }

                text += `${sequenceNumber},${plate},${carType},${enterTime},${exitTime},${parkTime}\n`
            });

            download('停車紀錄.csv', text);
        }catch(err) {
            hide();
            console.log(err);
            message.error('處理失敗');
            return;
        }
    
        hide();
        message.success('處理成功')
        
      }

    return (
        <Layout style={{ padding: 24, minHeight: 360 }}>
            <Content className="site-layout-background" style={{flex: 'none', padding: 20}}>
                <Content style={{height: '100%'}}>
                    群組:
                    <Select
                        showSearch
                        style={{ width: 200 }}
                        placeholder="群組"
                        optionFilterProp="children"
                        onChange={handleGroupChange}
                        defaultValue={`${group? group.id : '1'}`}
                        filterOption={(input, option) =>
                            option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {
                            groups.map(g => <Option key={g.id} value={`${g.id}`}>{g.name}</Option>)
                        }
                    </Select>
                    場站:
                    <Select
                        showSearch
                        style={{ width: 200 }}
                        placeholder="場站"
                        optionFilterProp="children"
                        onChange={handleSiteChange}
                        onSearch={handleSiteSearch}
                        defaultValue={`${site? site.id : '1'}`}
                        filterOption={(input, option) =>
                            option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {
                            sites.map(s => <Option key={s.id} value={`${s.id}`}>{s.name}</Option>)
                        }
                    </Select>

                    起始時間:
                    <DatePicker defaultValue={moment(startTime)} showTime onOk={handleStartTimeChange} />

                    結束時間:
                    <DatePicker defaultValue={moment(endTime)} showTime onOk={handleEndTimeChange} />

                    <Button type="primary" icon={<DownloadOutlined />} size='large' style={{marginLeft: 20}} onClick={() => handleExport()}>
                    匯出
                    </Button>
                </Content>
            </Content>

            <Content className="site-layout-background" style={{marginTop: 20, padding: 12}}>
                <Table dataSource={_data} columns={columns} loading={!loaded} pagination={{
                    defaultCurrent: pagination.current_page,
                    total: pagination.total_elements,
                    current: pagination.current_page,
                    pageSize: pagination.page_size,
                    onChange: (value, pageSize) => {
                        let next = {
                            ...pagination
                        };

                        if(pageSize != null) {
                            next.page_size = Number(pageSize);
                        }
                        
                        next.current_page = value;

                        setPagination(next);
                    }
                }} />
            </Content>
        </Layout>
    )
}

export default History;