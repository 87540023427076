import { Page } from '../type/base';
import zhTw from '../lang/zh_tw';

class Paramlizer {
    _value: any

    query(ctx: any, paramName: string) {
        this._value = ctx[paramName];
        return this;
    }
    
    default(value: any) {
        if(!this._value) {
            this._value = value;
        }

        return this;
    }

    value() {
        return this._value;
    }
}

const paramlizer = new Paramlizer();

class Translator {
    lang: any

    constructor(lang: any) {
        this.lang = lang;
    }

    translate(value: string) {
        if(this.lang[value]) {
            return this.lang[value];
        }

        return value;
    }
}

function pagelize(page: Page): Page {
    page.page = paramlizer.query(page, 'page').default(0).value();
    page.page_size = paramlizer.query(page, 'page_size').default(20).value();
    page.sort_by = paramlizer.query(page, 'sort_by').default('id').value();
    page.sort_direction = paramlizer.query(page, 'sort_direction').default('asc').value();
    return page;
}

const translator = new Translator(zhTw);

export { paramlizer, pagelize, translator };