import React, { useState, useEffect, useMemo, useRef } from 'react';

import { useParams, useHistory } from 'react-router-dom';

import { usePaginationFetcher } from '../component/hook';

import {
  Form,
  Input,
  Button,
  Select,
  Cascader,
  DatePicker,
  InputNumber,
  TreeSelect,
  Switch,
  Col,
  Spin,
  message,
  Divider,
  Typography,
  Card,
  Collapse,
  Table,
  Layout,
  Upload
} from 'antd';

import { RentalUser, Site, User } from '../type/park-cloud';

import { Pagination as PaginationType } from '../type/base';

import {
  DeleteOutlined,
  EditOutlined,
  UploadOutlined
} from '@ant-design/icons';

import { parkCloud } from '../util/util';
import { RcCustomRequestOptions } from 'antd/lib/upload/interface';
const { Title } = Typography;
const { Panel } = Collapse;
const { Option } = Select;
const { TextArea } = Input;

const { Content, Footer } = Layout;

const columns = [
  {
    title: '用戶名稱',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '動作',
    key: 'action',
    render: (value: any, record: User) => (
      <span>
        <Button icon={<DeleteOutlined />} style={{ marginLeft: 8 }}></Button>
      </span>
    ),
  },
];

const UsersTable = (props: any) => {

  const fetcher = useMemo(() => {
    return async (pagination: PaginationType) => {
      return parkCloud.fetchUsers({
        page: pagination.current_page,
        page_size: pagination.page_size
      });
    }
  }, []);

  const { loaded, error, results, pagination, setPagination } = usePaginationFetcher(fetcher, {
    current_page: 0,
    page_size: 20,
    total_elements: 100,
    total_pages: 5,
    sort: {
      by: 'id',
      direction: 'asc'
    }
  }, [] as User[]);

  const _data = useMemo(() => {
    return results.map(r => {
      return {
        ...r,
        key: r.id
      };
    })
  }, [results]);

  return (
    <Table dataSource={_data} columns={columns} loading={!loaded} pagination={{
      defaultCurrent: pagination.current_page + 1,
      total: pagination.total_elements,
      onChange: (value) => {
        let next = {
          ...pagination
        };

        next.current_page = value - 1;

        setPagination(next);
      }
    }} />
  )
}

const rentalColumns = [
  {
      title: '用戶名稱',
      dataIndex: 'name',
      key: 'name',
  },
  {
    title: '開始時間',
    dataIndex: 'start_time',
    key: 'start_time',
    render: (value: any, record: RentalUser) => (
        <span>
          {new Date(value).toLocaleString()}
        </span>
      ),
  },
  {
    title: '結束時間',
    dataIndex: 'end_time',
    key: 'end_time',
    render: (value: any, record: RentalUser) => (
        <span>
          {new Date(value).toLocaleString()}
        </span>
      ),
  },
  {
      title: '動作',
      key: 'action',
      render: (value: any, record: RentalUser) => (
        <span>
          <Button icon={<DeleteOutlined />} style={{marginLeft: 8}}></Button>
        </span>
      ),
    },
];


const RentalUserTable = (props: any) => {

  const fetcher = useMemo(() => {
    return async (pagination: PaginationType) => {
      let body = await parkCloud.fetchSiteRentalUsers(props.site.id, {
        page: pagination.current_page - 1,
        page_size: pagination.page_size
      });

      // backend start from zero
      body.pagination.current_page += 1;
      return body;
    }
  }, [props.site]);

  const { loaded, error, results, pagination, setPagination } = usePaginationFetcher(fetcher, {
    current_page: 1,
    page_size: 12,
    total_elements: 100,
    total_pages: 5,
    sort: {
      by: 'id',
      direction: 'asc'
    }
  }, [] as RentalUser[]);

  const _data = useMemo(() => {
    return results.map(r => {
      return {
        ...r,
        key: r.id
      };
    })
  }, [results]);

  return (
    <Table dataSource={_data as any} columns={rentalColumns} loading={!loaded} pagination={{
      defaultCurrent: pagination.current_page,
      total: pagination.total_elements,
      current: pagination.current_page,
      pageSize: pagination.page_size,
      onChange: (value) => {
        let next = {
          ...pagination
        };

        next.current_page = value;

        setPagination(next);
      }
    }} />
  )
}

const UserSelector = (props: any) => {

  const [searchText, setSearchText] = useState('');
  const [selected, setSelected] = useState<number | undefined>(1);
  const timeoutRef = useRef<NodeJS.Timeout>();

  const fetcher = useMemo(() => {
    return async (pagination: PaginationType) => {
      return parkCloud.fetchUsers({
        page: pagination.current_page,
        page_size: pagination.page_size
      }, {
        search_by: 'name',
        search_text: searchText
      });
    }
  }, [searchText]);

  const { loaded, error, results, fetch } = usePaginationFetcher(fetcher, {
    current_page: 0,
    page_size: 20,
    total_elements: 100,
    total_pages: 5,
    sort: {
      by: 'id',
      direction: 'asc'
    }
  }, [] as User[]);

  const handleSearch = (value: any) => {
    setSearchText(value);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = undefined;
    timeoutRef.current = setTimeout(() => {
      fetch();
    }, 200)
  }

  const options = useMemo(() => {
    return results.map(user => {
      return (
        <Option key={user.id} value={user.id}>{user.name}</Option>
      )
    });
  }, [results]);

  return (
    <div>
      <Form.Item label="用戶">
        <Select showSearch
          onSearch={handleSearch}
          value={selected}
          defaultActiveFirstOption={false}
          loading={!loaded}
          notFoundContent={null}
          filterOption={false}
          onChange={(value) => setSelected(value)}
        >


          {
            options
          }
        </Select>
      </Form.Item>
      <Form.Item label="職務">
        <Select defaultValue="1">
          <Option value="1">擁有者</Option>
          <Option value="2">組長</Option>
          <Option value="3">基層管理員</Option>
        </Select>
      </Form.Item>
    </div>
  )
}

const SiteForm = (props: any) => {

  const [site, setSite] = useState<Site | undefined>(undefined);
  const [loaded, setLoaded] = useState<boolean>(false);

  let { id } = useParams();
  let history = useHistory();

  id = Number(id);

  const init = async () => {
    if (id === 0) {
      setLoaded(true);
      return;
    }

    let _site = await parkCloud.fetchSite(id);

    setSite(_site);
    setLoaded(true);
  }

  useEffect(() => {
    init();
  }, []);

  const asyncSumbit = async (values: Site) => {
    values.total_car_number = Number(values.total_car_number);
    values.total_moto_number = Number(values.total_moto_number);

    values.lat = Number(values.lat);
    values.lon = Number(values.lon);

    let hide = message.loading('資料上傳中...');

    try {
      if (id === 0) {
        values.current_car_number = 0;
        values.current_moto_number = 0;
        await parkCloud.addSite(values);
      } else {
        values.id = id;
        await parkCloud.updateSite(values);
      }
    } catch (err) {
      message.error('失敗!');
      hide();
      return;
    }

    hide();
    message.success('成功!');

    history.goBack();
  }

  const submit = (values: any) => {
    asyncSumbit(values);
  }

  const handleUploadRentalUsers = (options: RcCustomRequestOptions) => {
    const { file, onProgress, onSuccess, onError } = options;

    async function upload() {
      try {
        let resp = await parkCloud.importSiteRentalUsers(site!.id, file);
        onSuccess(resp, file);
        message.info('上傳成功');
      } catch (err) {
        onError(err);
        message.error('上傳失敗!');
      }

      setSite({ ...site } as Site);
    }

    upload();
  }

  if (!loaded) {
    return (
      <div>
        <Spin></Spin>
      </div>
    )
  }

  return (
    <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
      <Form
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        initialValues={{ size: 'default' }}
        size={'default' as any}
        onFinish={submit}
      >



        <Collapse defaultActiveKey={['1']}>
          <Panel header={<Title level={4}>場站資訊</Title>} key="1">
            <Form.Item name={'name'} label="場站名稱" initialValue={site ? site.name : ''}>
              <Input />
            </Form.Item>
            <Form.Item name={'address'} label="場站地址" initialValue={site ? site.address : ''}>
              <Input />
            </Form.Item>
            <Form.Item name={'fee_rule'} label="收費資訊" initialValue={site ? site.fee_rule : ''}>
              <TextArea rows={4} />
            </Form.Item>
            <Form.Item name={'exhibition_info'} label="展期資訊" initialValue={site ? site.exhibition_info : ''}>
              <Input />
            </Form.Item>
            <Form.Item name={'lon'} label="經度" initialValue={site ? site.lon : ''}>
              <Input />
            </Form.Item>
            <Form.Item name={'lat'} label="緯度" initialValue={site ? site.lat : ''}>
              <Input />
            </Form.Item>
            <Form.Item name={'total_car_number'} label="汽車總車位" initialValue={site ? site.total_car_number : ''}>
              <Input />
            </Form.Item>
            <Form.Item name={'total_moto_number'} label="機車總車位" initialValue={site ? site.total_moto_number : ''}>
              <Input />
            </Form.Item>
            <div style={{ display: 'flex', marginTop: 20 }}>
              <div style={{ flex: 1 }}></div>
              <div style={{ flex: 4 }}><Button htmlType="submit" type="primary" block>提交</Button></div>
              <div style={{ flex: 1 }}></div>
            </div>
          </Panel>
          <Panel header={<Title level={4}>成員</Title>} key="2">
            <Card>
              <UserSelector site={site} />
            </Card>

            <Card style={{ marginTop: 20 }}>
              <UsersTable site={site} />
            </Card>
          </Panel>
          <Panel header={<Title level={4}>月租用戶</Title>} key="2">

            <Content style={{ flex: 'none', padding: 20, border: '1px solid', borderColor: '#aaa', borderRadius: 6, minHeight: 72 }}>
              <Content style={{ float: 'right', height: '100%' }}>
                <Upload
                  showUploadList={false}
                  customRequest={handleUploadRentalUsers}>
                  <Button type='primary' icon={<UploadOutlined />}>匯入</Button>
                </Upload>
              </Content>
            </Content>

            <Content style={{ marginTop: 20 }}>
              <RentalUserTable site={site} />
            </Content>
          </Panel>
        </Collapse>




      </Form>
    </div>)
}

export default SiteForm;