import React from 'react';
import { Form, Input, Button, Card, message } from 'antd';

import { updateUser } from '../state/user';

import { parkCloud } from '../util/util';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

const FloatCard = (props: any) => {
    const w = props.width || 500;
    const h = props.height || 560;

    const offset = {
        w: 0, h: 0
    };
    offset.w = w / 2;
    offset.h = h / 2;


    return (
        <div style={{ width: w, height: h, position: 'absolute', margin: `-${offset.h}px 0px 0px -${offset.w}px`, transform: `translate(50vw, 50vh)` }}>
            <Card style={{ height: '100%' }} {...props}>

            </Card>
        </div>

    )
}

const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 },
    
};
const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
};

const LoginForm = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const submit = async (values: any) => {
        try {
            let resp = await parkCloud.login({
                username: values.name,
                pass: values.pass
            });

            let tokens = resp.tokens;

            parkCloud.setToken(tokens[0]);

            let user = await parkCloud.fetchUser(resp.user_id);

            dispatch(updateUser({
                name: values.name,
                token: tokens[0],
                roles: user.roles
            }));

            history.replace('/')
        }catch(err) {
            message.error('使用者名稱或密碼錯誤!');
            return;
        }
        
    }

    const onFinish = (values: any) => {
        submit(values);
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <FloatCard>
            <Form
                {...layout}
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <Form.Item
                    label="使用者名稱"
                    name="name"
                    rules={[{ required: true, message: '請輸入使用者名稱!' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="密碼"
                    name="pass"
                    rules={[{ required: true, message: '請輸入密碼!' }]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit">
                        登入
                </Button>
                </Form.Item>
            </Form>
        </FloatCard>
    );
};

export default LoginForm;