import React, { useEffect, useMemo, useState } from 'react';

import { Layout, Table, Select, Row, Col, Card, message, Popover, Button, Modal, Pagination, Spin, } from 'antd';

import { parkCloud } from '../util/util';

import { Site, CarHistory, Group } from '../type/park-cloud';

import { Pagination as PaginationType } from '../type/base';

import { usePaginationFetcher } from '../component/hook';

import {
    PlusOutlined,
    EditOutlined,
    EllipsisOutlined,
    DeleteOutlined
} from '@ant-design/icons';

import { translator } from '../util/util';
import { Switch, useHistory, Link } from 'react-router-dom';

const { Content, Footer } = Layout;
const { Option } = Select;

const { confirm } = Modal;


const Extra = (props: any) => {
    const [visiable, setVisiable] = useState(false);

    let history = useHistory();

    let group = props.group as Site;

    const onEdit = () => {
        history.push(`/dashboard/group-form/${group.id}`);
    }

    const asyncDeleteSite = async () => {
        try {
            await parkCloud.deleteSite(group.id);
        }catch(err) {
            message.error('刪除失敗!');
            return;
        }

        message.success('刪除成功!');
        props.onDelete && props.onDelete();
    }

    const deleteSite = (evt: React.MouseEvent<HTMLElement>) => {

        confirm({
            title: '刪除場站',
            content: '確定刪除嗎?',
            okText: '確認',
            cancelText: '取消',
            onOk() {
                asyncDeleteSite();
            }
        })
    }
    
    return (
        <div onClick={(evt: React.MouseEvent<HTMLElement>) => {
            setVisiable(true);

            evt.preventDefault();
        }}>
            <EllipsisOutlined />

            <Popover
                placement='bottom'
                content={
                <div>
                    <Button type="text" icon={<EditOutlined/>} block onClick={onEdit}>修改</Button>
                    <Button type="text" icon={<DeleteOutlined/>} block onClick={deleteSite}>刪除</Button>
                </div>}
                trigger="click"
                visible={visiable}
                onVisibleChange={v => setVisiable(v)}
            ></Popover>
        </div>
    )
}

const History = (props: any) => {

    const fetcher = useMemo(() => {
        return async (pagination: PaginationType) => {
            return parkCloud.fetchGroups({
                page: pagination.current_page - 1,
                page_size: pagination.page_size,
                sort_direction: pagination.sort.direction
            });
        }
    }, []);

    const {loaded, error, results, pagination, setPagination} = usePaginationFetcher(fetcher, {
        current_page: 1,
        page_size: 12,
        total_elements: 100,
        total_pages: 5,
        sort: {
            by: 'id',
            direction: 'desc'
        }
    }, [] as Group[]);

    const _data = useMemo(() => {
        return results.map(r => {
            return {
                ...r,
                key: r.id
            };
        })
    }, [results]);

    if(!loaded) {
        return <Spin></Spin>
    }

    return (
        <Layout style={{ padding: 0, minHeight: 360 }}>

            <Row>
                {
                    _data.map(g => {
                        return (
                            <Col key={g.id} xs={{ span: 12 }} lg={{ span: 6}} style={{padding: 8}}>
                                

                                <Link to={`/dashboard/group-form/${g.id}`}>
                                    <Card title={g.name} bordered={true} hoverable 
                                    extra={<Extra 
                                        onDelete={() => {
                                            
                                        }}
                                        group={g} 
                                        />} 
                                    style={{ width: '100%' }}>
                                        <div>
                                            <span>租賃汽車數:</span>
                                            <span>{g.rental_car_number}</span>
                                        </div>

                                        <div>
                                            <span>租賃機車數:</span>
                                            <span>{g.rental_moto_number}</span>
                                        </div>
                                    </Card>
                                </Link>
                            </Col>
                            
                        )
                    })
                }
            </Row>

            <Footer style={{zIndex: 2}}>

                <div style={{margin: 'auto', display: 'block'}}>
                    <Pagination 
                    defaultCurrent={pagination.current_page} 
                    total={pagination.total_elements} 
                    pageSize={pagination.page_size}
                    onChange={(value) => {
                        let next = {
                            ...pagination
                        };

                        next.current_page = value;

                        setPagination(next);
                    }}
                    style={{margin: 'auto', display: 'block'}} />
                </div>

            </Footer>
        </Layout>
    )
}

export default History;