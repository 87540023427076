import React, { useEffect, useMemo } from 'react';

import { Layout, Table, Button } from 'antd';

import { parkCloud } from '../util/util';

import { Site, CarHistory, User } from '../type/park-cloud';

import { Pagination as PaginationType } from '../type/base';

import { usePaginationFetcher } from '../component/hook';

import { translator } from '../util/util';

import {
    DeleteOutlined,
    EditOutlined,
    PlusOutlined
} from '@ant-design/icons';
import { useHistory, Link } from 'react-router-dom';

const { Content } = Layout;


const columns = [
    {
        title: '用戶名稱',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: '電子郵件',
        dataIndex: 'emial',
        key: 'emial'
    },
    {
        title: '動作',
        key: 'action',
        render: (value: any, record: User) => (
          <span>
            <Link to={`/dashboard/user/${record.id}`}>
                <Button icon={<EditOutlined />}></Button>
            </Link>
            
            <Button icon={<DeleteOutlined />} style={{marginLeft: 8}}></Button>
          </span>
        ),
      },
];

const Users = (props: any) => {

    let history = useHistory();

    const fetcher = useMemo(() => {
        return async (pagination: PaginationType) => {
            return parkCloud.fetchUsers({
                page: pagination.current_page,
                page_size: pagination.page_size
            });
        }
    }, []);

    const {loaded, error, results, pagination, setPagination, fetch} = usePaginationFetcher(fetcher, {
        current_page: 0,
        page_size: 20,
        total_elements: 100,
        total_pages: 5,
        sort: {
            by: 'id',
            direction: 'asc'
        }
    }, [] as User[]);

    const _data = useMemo(() => {
        return results.map(r => {
            return {
                ...r,
                key: r.id
            };
        })
    }, [results]);

    const onAddUser = () => {
        history.push('/dashboard/user/0');
    }

    return (
        <Layout style={{ padding: 0, minHeight: 360 }}>
            <Content className="site-layout-background" style={{flex: 'none', padding: 20}}>
                <Content style={{float: 'right', height: '100%'}}>
                    <Button icon={<PlusOutlined />} onClick={onAddUser}>新增用戶</Button>
                </Content>
            </Content>

            <Content className="site-layout-background" style={{marginTop: 20, padding: 12}}>
                <Table dataSource={_data} columns={columns} loading={!loaded} pagination={{
                    defaultCurrent: pagination.current_page + 1,
                    total: pagination.total_elements,
                    pageSize: pagination.page_size,
                    onChange: (value) => {
                        let next = {
                            ...pagination
                        };

                        next.current_page = value - 1;

                        setPagination(next);
                        fetch();
                    }
                }} />
            </Content>
            
        </Layout>
    )
}

export default Users;