import { RouteConfig } from '../type/base';

import Login from '../view/login';
import Dashboard from '../layout/dashboard';

const routes = [
    {
        name: '登入',
        path: '/login',
        component: Login,
        privileges: ['all']
    },
    {
        name: '儀錶板',
        path: '/dashboard',
        component: Dashboard,
        privileges: ['all'],
    }
] as RouteConfig[];

export default routes;