import { RouteConfig } from '../type/base';

// views
import Users from '../view/users';
import Sites from '../view/sites';
import SiteForm from '../view/site-form';
import CarHistory from '../view/car-history';
import Groups from '../view/groups';
import GroupForm from '../view/group-form';
import ParkRecord from '../view/park-record';
import StayNightRecord from '../view/stay-night-record';

// icons
import {
    ShopOutlined,
    UserOutlined,
} from '@ant-design/icons';

const routes = [
    // menu items
    {
        icon: UserOutlined,
        name: '用戶',
        path: '/users',
        component: Users,
        privileges: ['all'],
        menuItem: true,
        rolePermissions: ['admin', 'manager']
    },
    {
        icon: ShopOutlined,
        name: '場站',
        path: '/sites',
        component: Sites,
        privileges: ['all'],
        menuItem: true,
        rolePermissions: ['admin', 'manager']
    },
    {
        icon: ShopOutlined,
        name: '群組',
        path: '/groups',
        component: Groups,
        privileges: ['all'],
        menuItem: true,
        rolePermissions: ['admin', 'manager', 'user']
    },
    {
        icon: ShopOutlined,
        name: '停車紀錄',
        path: '/car-history',
        component: CarHistory,
        privileges: ['all'],
        menuItem: true,
        rolePermissions: ['admin', 'manager', 'user']
    },

    {
        icon: ShopOutlined,
        name: '停車統計表',
        path: '/park-record',
        component: ParkRecord,
        privileges: ['all'],
        menuItem: true,
        rolePermissions: ['admin', 'manager', 'user']
    },

    {
        icon: ShopOutlined,
        name: '駐夜車統計表',
        path: '/stay-night-record',
        component: StayNightRecord,
        privileges: ['all'],
        menuItem: true,
        rolePermissions: ['admin', 'manager', 'user']
    },

    // hidden routes
    {
        icon: ShopOutlined,
        name: '場站表單',
        path: '/site-form/:id',
        component: SiteForm,
        privileges: ['all'],
        menuItem: false,
        rolePermissions: ['admin', 'manager']
    },

    {
        icon: ShopOutlined,
        name: '場站表單',
        path: '/group-form/:id',
        component: GroupForm,
        privileges: ['all'],
        menuItem: false,
        rolePermissions: ['admin', 'manager']
    }
] as RouteConfig[];

export default routes;