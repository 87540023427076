import { Site, User, CarHistory, PaginationBody, FeatchUsersOptions, Group, RentalUser } from '../type/park-cloud';

import crypto from 'crypto';
import { v4 as uuid} from 'uuid';

import { KeyMap, Page, Pagination } from '../type/base';

import { paramlizer, pagelize } from './_util';

export default class ParkCloud {
    token: string | undefined
    host: string

    constructor(host: string, token: string | undefined) {
        this.host = host;
        this.token = token;
    }

    _makeHeaders() {
        let headers = {
            'Content-Type': 'application/json'
        } as any;

        if(this.token) {
            headers['Authorization'] = `Token ${this.token}`;
        }
    
        return headers;
    }

    setToken(token: string) {
        this.token = token;
    }

    async salt(username: string): Promise<any> {
        let headers = this._makeHeaders();
    
        let resp =
            await fetch(`${this.host}/api/v1/user/salt?username=${username}`, {
                method: 'GET',
                headers,
                mode: 'cors'
            });
        let body = await resp.json();
        return body.content;
    }

    async login(form: any): Promise<any> {
        let resp = await this.salt(form.username);
        let salt = resp.salt;

        if(!salt) {
            throw new Error('cannot find user');
        }

        let headers = this._makeHeaders();

        let hmac = crypto.createHmac('sha256', salt);

        hmac.update(form.pass);
    
        let passHash = hmac.digest('hex').toLowerCase();

        let nonce = uuid().toLowerCase().replace(/-/g, '');

        hmac = crypto.createHmac('sha256', nonce);
        hmac.update(passHash);

        let sign = hmac.digest('hex').toLowerCase();
    
        resp =
            await fetch(`${this.host}/api/v1/user/login`, {
                method: 'POST',
                headers,
                mode: 'cors',
                body: JSON.stringify({
                    username: form.username,
                    sign,
                    nonce
                })
            });
        let body = await resp.json();
        return body.content;
    }

    async fetchUser(id: number): Promise<User> {
        let headers = this._makeHeaders();
    
        let resp =
            await fetch(`${this.host}/api/v1/user/${id}`, {
                method: 'GET',
                headers,
                mode: 'cors'
            });
        let body = await resp.json();
        return body.content;
    }

    async fetchUsers(page?: Page, options?: FeatchUsersOptions): Promise<PaginationBody<User[]>> {
        let headers = this._makeHeaders();

        let pairs: any[] = [];

        if(page) {
            pagelize(page);

            pairs = Object.keys(page).map(key => `${key}=${page[key]}`);
        }

        if(options && options.search_by && options.search_text) {
            pairs.push(...Object.keys(options).map(key => `${key}=${options[key]}`));
        }

        let params = pairs.join('&');
    
        let resp =
            await fetch(`${this.host}/api/v1/user?${params}`, {
                method: 'GET',
                headers,
                mode: 'cors'
            });
        let body = await resp.json();
        return body;
    }

    async fetchSites(page?: Page): Promise<PaginationBody<Site[]>> {
        let headers = this._makeHeaders();

        let pairs: any[] = [];

        if(page) {
            pagelize(page);

            pairs = Object.keys(page).map(key => `${key}=${page[key]}`);
        }

        let params = pairs.join('&');
    
        let resp =
            await fetch(`${this.host}/api/v1/site?${params}`, {
                method: 'GET',
                headers,
                mode: 'cors'
            });
        let body = await resp.json();
        return body;
    }

    async fetchSite(id: number): Promise<Site> {
        let headers = this._makeHeaders();
    
        let resp =
            await fetch(`${this.host}/api/v1/site/${id}`, {
                method: 'GET',
                headers,
                mode: 'cors'
            });
        let body = await resp.json();
        return body.content;
    }

    async updateSite(site: Site): Promise<Site> {
        let headers = this._makeHeaders();

        let id = site.id;

        delete site.id;
    
        let resp =
            await fetch(`${this.host}/api/v1/site/${id}`, {
                method: 'PUT',
                headers,
                mode: 'cors',
                body: JSON.stringify(site)
            });
        let body = await resp.json();
        return body.content;
    }

    async addSite(site: Site): Promise<Site> {
        let headers = this._makeHeaders();

        delete site.id;
    
        let resp =
            await fetch(`${this.host}/api/v1/site`, {
                method: 'POST',
                headers,
                mode: 'cors',
                body: JSON.stringify(site)
            });
        let body = await resp.json();
        return body.content;
    }

    async deleteSite(id: number): Promise<Site> {
        let headers = this._makeHeaders();
    
        let resp =
            await fetch(`${this.host}/api/v1/site/${id}`, {
                method: 'DELETE',
                headers,
                mode: 'cors'
            });
        let body = await resp.json();
        return body.content;
    }

    async fetchGroups(page?: Page): Promise<PaginationBody<Group[]>> {
        let headers = this._makeHeaders();

        let pairs: any[] = [];

        if(page) {
            pagelize(page);

            pairs = Object.keys(page).map(key => `${key}=${page[key]}`);
        }

        let params = pairs.join('&');
    
        let resp =
            await fetch(`${this.host}/api/v1/group?${params}`, {
                method: 'GET',
                headers,
                mode: 'cors'
            });
        let body = await resp.json();
        return body;
    }

    async fetchGroup(groupId: number): Promise<Group> {
        let headers = this._makeHeaders();

        let resp =
            await fetch(`${this.host}/api/v1/group/${groupId}`, {
                method: 'GET',
                headers,
                mode: 'cors'
            });
        let body = await resp.json();
        return body.content;
    }

    async fetchSiteRentalUsers(siteId: number, page?: Page, options?: FeatchUsersOptions): Promise<PaginationBody<RentalUser[]>> {
        let headers = this._makeHeaders();

        let pairs: any[] = [];

        if(page) {
            pagelize(page);

            pairs = Object.keys(page).map(key => `${key}=${page[key]}`);
        }

        if(options && options.search_by && options.search_text) {
            pairs.push(...Object.keys(options).map(key => `${key}=${options[key]}`));
        }

        let params = pairs.join('&');
    
        let resp =
            await fetch(`${this.host}/api/v1/site/${siteId}/rental-user?${params}`, {
                method: 'GET',
                headers,
                mode: 'cors'
            });
        let body = await resp.json();
        return body;
    }

    async fetchGroupRentalUsers(groupId: number, page?: Page, options?: FeatchUsersOptions): Promise<PaginationBody<RentalUser[]>> {
        let headers = this._makeHeaders();

        let pairs: any[] = [];

        if(page) {
            pagelize(page);

            pairs = Object.keys(page).map(key => `${key}=${page[key]}`);
        }

        if(options && options.search_by && options.search_text) {
            pairs.push(...Object.keys(options).map(key => `${key}=${options[key]}`));
        }

        let params = pairs.join('&');
    
        let resp =
            await fetch(`${this.host}/api/v1/group/${groupId}/rental-user?${params}`, {
                method: 'GET',
                headers,
                mode: 'cors'
            });
        let body = await resp.json();
        return body;
    }

    async fetchGroupSites(groupId: number, page?: Page, options?: FeatchUsersOptions): Promise<PaginationBody<Site[]>> {
        let headers = this._makeHeaders();

        let pairs: any[] = [];

        if(page) {
            pagelize(page);

            pairs = Object.keys(page).map(key => `${key}=${page[key]}`);
        }

        if(options && options.search_by && options.search_text) {
            pairs.push(...Object.keys(options).map(key => `${key}=${options[key]}`));
        }

        let params = pairs.join('&');
    
        let resp =
            await fetch(`${this.host}/api/v1/group/${groupId}/site?${params}`, {
                method: 'GET',
                headers,
                mode: 'cors'
            });
        let body = await resp.json();
        return body;
    }

    async updateGroupSite(groupId: number, site: Site): Promise<Site> {
        let headers = this._makeHeaders();
    
        let resp =
            await fetch(`${this.host}/api/v1/group/${groupId}/site/${site.id}`, {
                method: 'PUT',
                headers,
                mode: 'cors',
                body: JSON.stringify(site)
            });
        let body = await resp.json();
        return body.content;
    }

    async importGroupRentalUsers(groupId: number, file: Blob): Promise<PaginationBody<RentalUser[]>> {
        let headers = this._makeHeaders();
        delete headers['Content-Type']

        const formData = new FormData()
        formData.append('file', file, 'rental-users.csv');
        
    
        let resp =
            await fetch(`${this.host}/api/v1/group/${groupId}/rental-user/import`, {
                method: 'POST',
                headers,
                mode: 'cors',
                body: formData
            });
        let body = await resp.json();
        return body;
    }

    async importSiteRentalUsers(siteId: number, file: Blob): Promise<PaginationBody<RentalUser[]>> {
        let headers = this._makeHeaders();
        delete headers['Content-Type']

        const formData = new FormData()
        formData.append('file', file, 'rental-users.csv');
        
    
        let resp =
            await fetch(`${this.host}/api/v1/site/${siteId}/rental-user/import`, {
                method: 'POST',
                headers,
                mode: 'cors',
                body: formData
            });
        let body = await resp.json();
        return body;
    }

    async fetchCarHistory(siteId: number, page: Page): Promise<PaginationBody<CarHistory[]>> {
        let headers = this._makeHeaders();

        let pairs: any[] = [];

        if(page) {
            pagelize(page);

            pairs = Object.keys(page).map(key => `${key}=${page[key]}`);
        }

        let params = pairs.join('&');
    
        let resp =
            await fetch(`${this.host}/api/v1/site/${siteId}/car-history?${params}`, {
                method: 'GET',
                headers,
                mode: 'cors'
            });
        let body = await resp.json();
        return body;
    }

    async fetchGroupSiteUsageReport(groupId: number, siteId: number, opts: any): Promise<any[]> {
        let headers = this._makeHeaders();

        let pairs: any[] = [];

        if(opts) {
            pairs = Object.keys(opts).map(key => `${key}=${opts[key]}`);
        }

        let params = pairs.join('&');
    
        let resp =
            await fetch(`${this.host}/api/v1/group/${groupId}/site/${siteId}/usage-report?${params}`, {
                method: 'GET',
                headers,
                mode: 'cors'
            });
        let body = await resp.json();
        return body.content;
    }

    async fetchStayNightReport(groupId: number, siteId: number, opts: any): Promise<any[]> {
        let headers = this._makeHeaders();

        let pairs: any[] = [];

        if(opts) {
            pairs = Object.keys(opts).map(key => `${key}=${opts[key]}`);
        }

        let params = pairs.join('&');
    
        let resp =
            await fetch(`${this.host}/api/v1/group/${groupId}/site/${siteId}/stay-night-report?${params}`, {
                method: 'GET',
                headers,
                mode: 'cors'
            });
        let body = await resp.json();
        return body.content;
    }
}